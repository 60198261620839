import { request } from "@/request/request";
import { parameterChange } from "@/utils";

export default {
  // 课程列表
  getOnlineCourseList (data) {
    return request({
      url: "/online_course/courseList?" + parameterChange(data),
      method: "get",
      data,
    });
  },
  // 课程详情
  getOnlineCourseDetail (data) {
    return request({
      url: "/online_course/courseDetail?" + parameterChange(data),
      method: "get",
      data,
    });
  },
  // 讲师下拉列表
  lecturerList (data) {
    return request({
      url: "/lecturer/lecturerList?" + parameterChange(data),
      method: "get",
      data,
    });
  },
  // 标签下拉列表
  tagList (data) {
    return request({
      url: "/tag/tagList?" + parameterChange(data),
      method: "get",
      data,
    });
  },
  // 课程新增
  onlineCourseAdd (data) {
    return request({
      url: "/online_course/courseAdd",
      method: "post",
      data,
    });
  },
  // 课程编辑
  onlineCourseUpdate (data) {
    return request({
      url: "/online_course/courseUpdate",
      method: "post",
      data,
    });
  },
  // 课程删除
  onlineCourseDelete (data) {
    return request({
      url: "/online_course/courseDelete",
      method: "post",
      data,
    });
  },
  // 目录编辑
  catalogUpdate (data) {
    return request({
      url: "/online_course/catalogUpdate",
      method: "post",
      data,
    });
  },
  // 目录新增
  catalogAdd (data) {
    return request({
      url: "/online_course/catalogAdd",
      method: "post",
      data,
    });
  },
  // 目录删除
  catalogDelete (data) {
    return request({
      url: "/online_course/catalogDelete",
      method: "post",
      data,
    });
  },
  // 评价列表
  evaluatePageList (data) {
    return request({
      url: "/evaluate/evaluatePageList?" + parameterChange(data),
      method: "get",
      data,
    });
  },
  // 删除评价
  evaluateDelete (data) {
    return request({
      url: "/evaluate/evaluateDelete",
      method: "post",
      data,
    });
  },
};
