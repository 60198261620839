import { request } from "@/request/request";
import { parameterChange } from "@/utils";

export default {
  // 数据统计
  homeCount(data) {
    return request({
      url: "/homepage/homeCount",
      method: "get",
      data,
    });
  },
  // 是否展示视频
  getVideoShowStatus(data) {
    return request({
      url: "/homepage/getVideoShowStatus",
      method: "get",
      data,
    });
  },
  // 更新是否展示视频
  updateVideoShowStatus(data) {
    return request({
      url: "/homepage/updateVideoShowStatus",
      method: "post",
      data,
    });
  },
};
