import { request } from "@/request/request";
import { parameterChange } from "@/utils";

export default {
  // 教师列表
  teacherPageList(data) {
    return request({
      url: "/teacher/teacherPageList?" + parameterChange(data),
      method: "get",
    });
  },
  // 讲师新增
  teacherAdd(data) {
    return request({
      url: "/teacher/teacherAdd",
      method: "post",
      data,
    });
  },
  // 讲师编辑
  teacherUpdate(data) {
    return request({
      url: "/teacher/teacherUpdate",
      method: "post",
      data,
    });
  },
  // 讲师详情
  teacherDetail(data) {
    return request({
      url: "/teacher/teacherDetail?" + parameterChange(data),
      method: "get",
    });
  },
  // 讲师删除
  teacherDelete(data) {
    return request({
      url: "/teacher/teacherDelete",
      method: "post",
      data,
    });
  },
  // 教师下拉列表
  teacherList(data) {
    return request({
      url: "/teacher/teacherList?" + parameterChange(data),
      method: "get",
    });
  },
  // 教师下拉列表
  teacherAssociate(data) {
    return request({
      url: "/teacher/teacherAssociate",
      method: "post",
      data,
    });
  },
};
