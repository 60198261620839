import { request } from "@/request/request";
import { parameterChange } from "@/utils";

export default {
  // 课程列表
  offlineCourseList(data) {
    return request({
      url: "/offline_course/courseList?" + parameterChange(data),
      method: "get",
    });
  },
  // 课程详情
  offlineCourseDetail(data) {
    return request({
      url: "/offline_course/courseDetail?" + parameterChange(data),
      method: "get",
    });
  },
  // 课程新增
  offlineCourseAdd(data) {
    return request({
      url: "/offline_course/courseAdd",
      method: "post",
      data,
    });
  },
  // 课程编辑
  offlineCourseUpdate(data) {
    return request({
      url: "/offline_course/courseUpdate",
      method: "post",
      data,
    });
  },
  // 课程删除
  offlineCourseDelete(data) {
    return request({
      url: "/offline_course/courseDelete",
      method: "post",
      data,
    });
  },
  // 报名列表
  enrollList(data) {
    return request({
      url: "/enroll/enrollList?" + parameterChange(data),
      method: "get",
      data,
    });
  },
  // 报名表单
  getEnrollForm(data) {
    return request({
      url: "/offline_course/getEnrollForm?" + parameterChange(data),
      method: "get",
      data,
    });
  },
  // 导出报名信息
  enrollExcelExport(data) {
    return request({
      url: "/enroll/enrollExcelExport?" + parameterChange(data),
      method: "get",
      data,
    });
  },
  // 生成小程序码
  getWXACode(data) {
    return request({
      url: "/offline_course/getWXACode",
      method: "post",
      data,
    });
  },
  // 生成小程序链接
  getWxURLLink(data) {
    return request({
      url: "/offline_course/getWXURLLink",
      method: "post",
      data,
    });
  },
  // 更新候补状态
  enrollStateUpdate(data) {
    return request({
      url: "/enroll/enrollStateUpdate",
      method: "post",
      data,
    });
  },
  // 更新直播间配置
  liveUpdate(data) {
    return request({
      url: "/offline_course/liveUpdate",
      method: "post",
      data,
    });
  },
};
