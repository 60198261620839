<template>
  <a-cascader
    :placeholder="item.placeholder || `请选择${item.label}`"
    :options="item.options"
    :load-data="item.loadData"
    :bordered="bordered"
    :disabled="disabled"
    v-model:value="value"
    expandTrigger="hover"
    changeOnSelect
  />
</template>

<script setup>
import { ref, reactive, getCurrentInstance, watch, onMounted } from "vue";

const { proxy } = getCurrentInstance();

const props = defineProps({
    item: { type: Object, default: {} },
    bordered: { type: Boolean, default: true },
    disabled: { type: Boolean, default: false },
  }),
  emits = defineEmits(["value"]);

onMounted(() => {
  if (props.item.value) value.value = props.item.value;
});

const value = ref(undefined);

watch(value, (data) => {
  emits("value", data);
});
</script>

<style lang="less" scoped>
.ant-select {
  width: 100%;
}
</style>
