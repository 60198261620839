/*
 * @Date: 2023-02-14 09:56:14
 * @LastEditTime: 2023-05-11 11:08:21
 */
import axios from 'axios';
import { useUserStore } from '@/store/index';
import { message } from 'ant-design-vue';
import router from '@/router';

const request = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  timeout: 60000,
  headers: {},
});

// 异常拦截处理器
const errorHandler = (error) => {
  return Promise.reject(error);
};

request.interceptors.request.use((config) => {
  const counter = useUserStore();
  config.headers.Authorization = counter.token;
  return config;
}, errorHandler);

request.interceptors.response.use((response) => {
  // console.log(response);
  switch (response.data.code) {
    case 200:
      return response.data;
    case 301:
    case 2001:
    case 2007:
      message.warning(response.data.msg, 2);
      router.push({ name: 'login' });
      break;
    default:
      message.warning(response.data.msg, 2);
      return response.data.success;
  }
}, errorHandler);

export { request };
