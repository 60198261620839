import { request } from "@/request/request";
import { parameterChange } from "@/utils";

export default {
  // 讲师列表
  lecturerPageList (data) {
    return request({
      url: "/lecturer/lecturerPageList?" + parameterChange(data),
      method: "get",
    });
  },
  // 讲师新增
  lecturerAdd (data) {
    return request({
      url: "/lecturer/lecturerAdd",
      method: "post",
      data,
    });
  },
  // 讲师编辑
  lecturerUpdate (data) {
    return request({
      url: "/lecturer/lecturerUpdate",
      method: "post",
      data,
    });
  },
  // 讲师详情
  lecturerDetail (data) {
    return request({
      url: "/lecturer/lecturerDetail?" + parameterChange(data),
      method: "get",
    });
  },
  // 讲师删除
  lecturerDelete (data) {
    return request({
      url: "/lecturer/lecturerDelete",
      method: "post",
      data,
    });
  },
};
