/*
 * @Author: gyh
 * @Date: 2024-04-17 10:39:12
 * @LastEditTime: 2024-06-08 09:52:28
 */
const locationMap = {
  INVALID_USER_KEY: "key不正确或过期",
  SERVICE_NOT_AVAILABLE: "服务不可用",
  INVALID_USER_SCODE: "安全码未通过验证",
  INVALID_USER_DOMAIN: "绑定域名无效",
  USERKEY_PLAT_NOMATCH: "请求key与绑定平台不符",
  NOT_SUPPORT_HTTPS: "服务不支持https请求",
  INSUFFICIENT_PRIVILEGES: "权限不足，服务请求被拒绝",
  USER_KEY_RECYCLED: "Key被删除",
  INVALID_PARAMS: "请求参数非法",
  MISSING_REQUIRED_PARAMS: "缺少必填参数",
  UNKNOWN_ERROR: "其他未知错误",
  OUT_OF_SERVICE: "规划点（包括起点、终点、途经点）不在中国陆地范围内",
  NO_ROADS_NEARBY: "划点（起点、终点、途经点）附近搜不到路",
  ROUTE_FAIL: "路线计算失败，通常是由于道路连通关系导致",
  OVER_DIRECTION_RANGE: "起点终点距离过长。",
  ENGINE_RESPONSE_DATA_ERROR: "响应失败，请输入具体地址。",
};

export { locationMap };
