<template>
  <Header />
  <div class="baseLayout">
    <directory />
    <router-view />
  </div>
</template>

<script setup>
import { ref, reactive, getCurrentInstance } from "vue";
import { directory, head as Header } from "@/component/index";

const { proxy } = getCurrentInstance();
</script>

<style lang="less" scoped>
.baseLayout {
  width: 100%;
  height: 0;
  flex: 1;
  display: flex;
  justify-content: space-between;
  overflow-y: hidden;
}
</style>
