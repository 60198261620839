import { request } from "@/request/request";
import { parameterChange } from "@/utils";

export default {
  // 获取七牛云token
  getToken(data) {
    return request({
      url: "/file/getToken?" + parameterChange(data),
      method: "get",
    });
  },
};
