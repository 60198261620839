import { request } from "@/request/request";
import { parameterChange } from "@/utils";

export default {
  // 课程分类列表
  classifyList(data) {
    return request({
      url: "/classify/classifyList?" + parameterChange(data),
      method: "get",
      data,
    });
  },
  // 课程分类详情
  classifyDetail(data) {
    return request({
      url: "/classify/classifyDetail?" + parameterChange(data),
      method: "get",
    });
  },
  // 课程分类新增
  classifyAdd(data) {
    return request({
      url: "/classify/classifyAdd",
      method: "post",
      data,
    });
  },
  // 课程分类编辑
  classifyUpdate(data) {
    return request({
      url: "/classify/classifyUpdate",
      method: "post",
      data,
    });
  },
  // 课程分类删除
  classifyDelete(data) {
    return request({
      url: "/classify/classifyDelete",
      method: "post",
      data,
    });
  },
};
