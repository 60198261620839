import BaseLayout from '@/layout/BaseLayout';

export default [
  {
    path: '/',
    redirect: '/index',
  },
  {
    path: '/index',
    name: 'index',
    redirect: '/home',
    component: BaseLayout,
    children: [
      {
        path: '/home',
        name: 'home',
        component: () => import('@/views/home/index.vue'),
      },
      {
        path: '/course',
        name: 'course',
        children: [
          {
            path: '/course/online',
            name: 'online',
            component: () => import('@/views/course/online/index.vue'),
          },
          {
            path: '/course/offline',
            name: 'offline',
            component: () => import('@/views/course/offline/index.vue'),
          },
          {
            path: '/course/tag',
            name: 'tag',
            component: () => import('@/views/course/tag/index.vue'),
          },
          {
            path: '/course/lecturer',
            name: 'lecturer',
            component: () => import('@/views/course/lecturer/index.vue'),
          },
        ],
      },
      {
        path: '/activity',
        redirect: '/activity/index',
        children: [
          {
            path: '/activity/index',
            name: 'activity',
            component: () => import('@/views/activity/index/index.vue'),
          },
          {
            path: '/activity/parties',
            name: 'parties',
            component: () => import('@/views/activity/parties/index.vue'),
          },
        ],
      },
      {
        path: '/banner',
        name: 'banner',
        component: () => import('@/views/banner/index.vue'),
      },
      {
        path: '/user',
        children: [
          {
            path: '/user/search',
            name: 'search',
            component: () => import('@/views/user/search/index.vue'),
          },
          {
            path: '/user/userManage',
            name: 'userManage',
            component: () => import('@/views/user/manage/index.vue'),
          },
          {
            path: '/user/certification',
            name: 'certification',
            component: () => import('@/views/user/certification/index.vue'),
          },
        ],
      },
      {
        path: '/team',
        name: 'team',
        children: [
          {
            path: '/team/manage',
            name: 'teamManage',
            component: () => import('@/views/team/manage/index.vue'),
          },
          {
            path: '/team/teacher',
            name: 'teamTeacher',
            component: () => import('@/views/team/teacher/index.vue'),
          },
          {
            path: '/team/class',
            name: 'teamClass',
            component: () => import('@/views/team/class/index.vue'),
          },
        ],
      },
      {
        path: '/notice',
        name: 'notice',
        component: () => import('@/views/notice/index.vue'),
      },
      {
        path: '/system',
        name: 'system',
        children: [
          {
            path: '/system/administer',
            name: 'administer',
            component: () => import('@/views/system/administer/index.vue'),
          },
          {
            path: '/system/adminTeam',
            name: 'adminTeam',
            component: () => import('@/views/system/administer/team/index.vue'),
          },
          {
            path: '/system/role',
            name: 'role',
            component: () => import('@/views/system/role/index.vue'),
          },
          {
            path: '/system/authority',
            name: 'authority',
            component: () => import('@/views/system/authority/index.vue'),
          },
          {
            path: '/system/setting',
            name: 'setting',
            component: () => import('@/views/system/setting/index.vue'),
          },
        ],
      },
    ],
  },
];
