import { request } from "@/request/request";
import { parameterChange } from "@/utils";

export default {
  // 团队列表
  teamPageList(data) {
    return request({
      url: "/team/teamPageList?" + parameterChange(data),
      method: "get",
      data,
    });
  },
  // 团队新增
  teamAdd(data) {
    return request({
      url: "/team/teamAdd",
      method: "post",
      data,
    });
  },
  // 团队编辑
  teamUpdate(data) {
    return request({
      url: "/team/teamUpdate",
      method: "post",
      data,
    });
  },
  // 团队删除
  teamDelete(data) {
    return request({
      url: "/team/teamDelete",
      method: "post",
      data,
    });
  },
  // 团队详情
  teamDetail(data) {
    return request({
      url: "/team/teamDetail?" + parameterChange(data),
      method: "get",
      data,
    });
  },
  // 团队下拉列表
  teamList(data) {
    return request({
      url: "/team/teamList?" + parameterChange(data),
      method: "get",
      data,
    });
  },
  // 团队班级树状列表
  teamClassList(data) {
    return request({
      url: "/team/teamClassList?" + parameterChange(data),
      method: "get",
      data,
    });
  },
};
