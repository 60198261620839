import { defineStore } from 'pinia';
import { h } from 'vue';
import {
  HomeOutlined,
  UserSwitchOutlined,
  SettingOutlined,
  ReadOutlined,
  BellOutlined,
  UserOutlined,
  TeamOutlined,
  BookOutlined,
  FileSearchOutlined,
  AppstoreOutlined,
  BarsOutlined,
} from '@ant-design/icons-vue';

const useDirectoryStore = defineStore('directory', {
  state: () => ({
    list: [],
    source: [
      {
        icon: 'HomeOutlined',
        title: '首页',
        name: 'home',
        path: '/home',
        component: 'views/home/index.vue',
      },
      {
        icon: 'ReadOutlined',
        title: '课程管理',
        name: null,
        path: null,
        component: null,
        children: [
          {
            icon: 'BarsOutlined',
            title: '线上课',
            name: 'online',
            path: '/course/online',
            component: 'views/course/online/index.vue',
          },
          {
            icon: 'BarsOutlined',
            title: '线下课',
            name: 'offline',
            path: '/course/offline',
            component: 'views/course/offline/index.vue',
          },
          {
            icon: 'BarsOutlined',
            title: '讲师',
            name: 'lecturer',
            path: '/course/lecturer',
            component: 'views/course/lecturer/index.vue',
          },
        ],
      },
      {
        icon: 'ReadOutlined',
        title: '活动管理',
        name: null,
        path: null,
        component: null,
        children: [
          {
            icon: 'BarsOutlined',
            title: '活动',
            name: 'activity',
            path: '/activity/index',
            component: 'views/activity/index/index.vue',
          },
          {
            icon: 'BarsOutlined',
            title: '关系方',
            name: 'parties',
            path: '/activity/parties',
            component: 'views/activity/parties/index.vue',
          },
        ],
      },
      {
        icon: 'BarsOutlined',
        title: '分类',
        name: 'tag',
        path: '/tag',
        component: 'views/course/tag/index.vue',
      },
      {
        icon: 'AppstoreOutlined',
        title: '轮播管理',
        name: 'banner',
        path: '/banner',
        component: 'views/banner/index.vue',
      },
      {
        icon: 'FileSearchOutlined',
        title: '搜索管理',
        name: 'search',
        path: '/user/search',
        component: 'views/user/search/index.vue',
      },
      {
        icon: BellOutlined,
        title: '消息管理',
        name: 'notice',
        path: '/notice',
        component: 'views/notice/index.vue',
      },
      {
        icon: 'UserOutlined',
        title: '用户管理',
        name: 'userManage',
        path: '/user/userManage',
        component: 'views/user/manage/index.vue',
      },
      {
        icon: 'TeamOutlined',
        title: '团队管理',
        children: [
          {
            icon: 'BarsOutlined',
            title: '团队',
            name: 'teamManage',
            path: '/team/manage',
            component: 'views/team/manage/index.vue',
          },
          {
            icon: 'BarsOutlined',
            title: '班级',
            name: 'teamClass',
            path: '/team/class',
            component: 'views/team/class/index.vue',
          },
          {
            icon: 'BarsOutlined',
            title: '教师',
            name: 'teamTeacher',
            path: '/team/teacher',
            component: 'views/team/teacher/index.vue',
          },
        ],
      },
      {
        icon: 'BookOutlined',
        title: '证书管理',
        name: 'certification',
        path: '/user/certification',
        component: 'views/user/certification/index.vue',
      },
      {
        icon: 'ReadOutlined',
        title: '管理员团队',
        name: 'adminTeam',
        path: '/system/adminTeam',
        component: 'views/system/administer/team/index.vue',
      },
      {
        icon: 'SettingOutlined',
        title: '系统设置',
        children: [
          {
            icon: 'BarsOutlined',
            title: '管理员',
            name: 'administer',
            path: '/system/administer',
            component: 'views/system/administer/index.vue',
          },
          {
            icon: 'BarsOutlined',
            title: '角色',
            name: 'role',
            path: '/system/role',
            component: 'views/system/role/index.vue',
          },
          {
            icon: 'BarsOutlined',
            title: '权限',
            name: 'authority',
            path: '/setting/authority',
            component: 'views/setting/authority/index.vue',
          },
          {
            icon: 'BarsOutlined',
            title: '设置',
            name: 'setting',
            path: '/setting/setting',
            component: 'views/setting/setting/index.vue',
          },
        ],
      },
    ],
    menu: [
      {
        key: 1,
        icon: () => h(HomeOutlined),
        label: '首页',
        title: '首页',
        name: 'home',
      },
      {
        key: 2,
        icon: () => h(ReadOutlined),
        label: '课程管理',
        title: '课程管理',
        children: [
          {
            key: '2-1',
            icon: () => h(BarsOutlined),
            label: '线上课',
            title: '线上课',
            name: 'online',
          },
          {
            key: '2-2',
            icon: () => h(BarsOutlined),
            label: '线下课',
            title: '线下课',
            name: 'offline',
          },
          {
            key: '2-3',
            icon: () => h(BarsOutlined),
            label: '讲师',
            title: '讲师',
            name: 'lecturer',
          },
        ],
      },
      {
        key: 3,
        icon: () => h(ReadOutlined),
        label: '活动管理',
        title: '活动管理',
        children: [
          {
            key: '3-1',
            icon: () => h(BarsOutlined),
            label: '活动',
            title: '活动',
            name: 'activity',
          },
          {
            key: '3-2',
            icon: () => h(BarsOutlined),
            label: '关系方',
            title: '关系方',
            name: 'parties',
          },
        ],
      },
      {
        key: 11,
        icon: () => h(BarsOutlined),
        label: '分类管理',
        title: '分类',
        name: 'tag',
      },
      {
        key: 4,
        icon: () => h(AppstoreOutlined),
        label: '轮播管理',
        title: '轮播管理',
        name: 'banner',
      },
      {
        key: 5,
        icon: () => h(FileSearchOutlined),
        label: '搜索管理',
        title: '搜索管理',
        name: 'search',
      },
      {
        key: 6,
        icon: () => h(BellOutlined),
        label: '消息管理',
        title: '消息管理',
        name: 'notice',
      },
      {
        key: 7,
        icon: () => h(UserOutlined),
        label: '用户管理',
        title: '用户管理',
        name: 'userManage',
      },
      {
        key: 8,
        icon: () => h(TeamOutlined),
        label: '团队管理',
        title: '团队管理',
        children: [
          {
            key: '8-1',
            icon: () => h(BarsOutlined),
            label: '团队',
            title: '团队',
            name: 'teamManage',
          },
          {
            key: '8-2',
            icon: () => h(BarsOutlined),
            label: '班级',
            title: '班级',
            name: 'teamClass',
          },
          {
            key: '8-3',
            icon: () => h(BarsOutlined),
            label: '教师',
            title: '教师',
            name: 'teamTeacher',
          },
        ],
      },
      {
        key: 9,
        icon: () => h(BookOutlined),
        label: '证书管理',
        title: '证书管理',
        name: 'certification',
      },
      {
        key: 10,
        icon: () => h(SettingOutlined),
        label: '系统设置',
        title: '系统设置',
        children: [
          {
            key: '10-1',
            icon: () => h(BarsOutlined),
            label: '管理员',
            title: '管理员',
            name: 'administer',
          },
          {
            key: '10-2',
            icon: () => h(BarsOutlined),
            label: '角色',
            title: '角色',
            name: 'role',
          },
          {
            key: '10-3',
            icon: () => h(BarsOutlined),
            label: '权限',
            title: '权限',
            name: 'authority',
          },
          {
            key: '10-4',
            icon: () => h(BarsOutlined),
            label: '设置',
            title: '设置',
            name: 'setting',
          },
        ],
      },
    ],
  }),
  getters: {},
  actions: {
    SET_LIST(list) {
      this.list = list;
    },
  },
});

export default useDirectoryStore;
