<!--
 * @Date: 2023-02-14 09:56:13
 * @LastEditTime: 2023-02-15 10:22:39
-->
<template>
  <router-view />
</template>

<script setup>
import dayjs from "dayjs";
import "dayjs/locale/zh-cn";
import { useUserStore } from "./store";

dayjs.locale("zh-cn");

const userStore = useUserStore();
</script>
