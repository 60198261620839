<template>
  <div class="head" ref="headRef">
    <div class="info_box" @click="$router.push({ name: 'home' })">
      <img src="@/assets/logo.png" alt="" class="logo" />
      <p class="text">8020健康平台管理系统</p>
    </div>

    <a-dropdown>
      <a class="ant-dropdown-link" @click.prevent>
        <UserOutlined />
        {{ userStore.account }}
        <DownOutlined />
      </a>
      <template #overlay>
        <a-menu>
          <a-menu-item @click="logout">
            <LogoutOutlined />
            <span class="text">退出登录</span>
          </a-menu-item>
        </a-menu>
      </template>
    </a-dropdown>
  </div>
</template>

<script setup>
import { useUserStore } from "@/store";
import { ref, reactive, getCurrentInstance } from "vue";
import {
  DownOutlined,
  UserOutlined,
  LogoutOutlined,
} from "@ant-design/icons-vue";

const { proxy } = getCurrentInstance();
const userStore = useUserStore();

const logout = () => {
  userStore.logout();
};
</script>

<style lang="less" scoped>
.head {
  width: 100%;
  height: 56px;
  border-bottom: 1px solid #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  box-sizing: border-box;

  .info_box {
    width: fit-content;
    height: 100%;
    padding: 10px 18px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    .logo {
      height: 100%;
      object-fit: contain;
    }

    .text {
      font-size: 24px;
      color: #13b9ac;
    }
  }

  a.ant-dropdown-link.ant-dropdown-trigger {
    cursor: pointer;
  }

  .anticon-down {
    font-size: 12px;
  }

  .text {
    margin-left: 5px;
  }
}
</style>
