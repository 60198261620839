import { request } from "@/request/request";
import { parameterChange } from "@/utils";

export default {
  // 轮播图列表
  bannerList(data) {
    return request({
      url: "/banner/bannerList?" + parameterChange(data),
      method: "get",
      data,
    });
  },
  // 轮播图详情
  bannerDetail(data) {
    return request({
      url: "/banner/bannerDetail?" + parameterChange(data),
      method: "get",
    });
  },
  // 轮播图新增
  bannerAdd(data) {
    return request({
      url: "/banner/bannerAdd",
      method: "post",
      data,
    });
  },
  // 轮播图编辑
  bannerUpdate(data) {
    return request({
      url: "/banner/bannerUpdate",
      method: "post",
      data,
    });
  },
  // 轮播图删除
  bannerDelete(data) {
    return request({
      url: "/banner/bannerDelete",
      method: "post",
      data,
    });
  },
  // 获取轮播图间隔
  getBannerInterval(data) {
    return request({
      url: "/banner/getBannerInterval",
      method: "get",
      data,
    });
  },
  // 更新轮播图间隔
  updateBannerInterval(data) {
    return request({
      url: "/banner/updateBannerInterval",
      method: "post",
      data,
    });
  },
};
