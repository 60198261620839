import { request } from '@/request/request';
import { parameterChange } from '@/utils';

export default {
  // 报名表单列表
  enrollFormList(data) {
    return request({
      url: '/enrollForm/enrollFormList?' + parameterChange(data),
      method: 'get',
      data,
    });
  },
  // 报名表单详情
  enrollFormDetail(data) {
    return request({
      url: "/enrollForm/enrollFormDetail?" + parameterChange(data),
      method: "get",
    });
  },
  // 报名表单新增
  enrollFormAdd(data) {
    return request({
      url: "/enrollForm/enrollFormAdd",
      method: "post",
      data,
    });
  },
  // 报名表单编辑
  enrollFormUpdate(data) {
    return request({
      url: "/enrollForm/enrollFormUpdate",
      method: "post",
      data,
    });
  },
  // 报名表单删除
  enrollFormDelete(data) {
    return request({
      url: "/enrollForm/enrollFormDelete",
      method: "post",
      data,
    });
  },
};
