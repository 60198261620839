import { request } from "@/request/request";
import { parameterChange } from "@/utils";

export default {
  // 课程下拉列表
  messageCourseList(data) {
    return request({
      url: "/message/courseList?" + parameterChange(data),
      method: "get",
      data,
    });
  },
  // 消息列表
  messageList(data) {
    return request({
      url: "/message/messageList?" + parameterChange(data),
      method: "get",
      data,
    });
  },
  // 消息详情
  messageDetail(data) {
    return request({
      url: "/message/messageDetail?" + parameterChange(data),
      method: "get",
      data,
    });
  },
  // 消息新增
  messageAdd(data) {
    return request({
      url: "/message/messageAdd",
      method: "post",
      data,
    });
  },
  // 课程删除
  messageDelete(data) {
    return request({
      url: "/message/messageDelete",
      method: "post",
      data,
    });
  },
  // 课程删除
  courseInfo(data) {
    return request({
      url: "/message/courseInfo?" + parameterChange(data),
      method: "get",
      data,
    });
  },
};
