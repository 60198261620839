import { request } from '@/request/request';
import { parameterChange } from '@/utils';

export default {
  // 标签列表
  tagPageList(data) {
    return request({
      url: "/tag/tagPageList?" + parameterChange(data),
      method: "get",
      data,
    });
  },
  // 标签新增
  tagAdd(data) {
    return request({
      url: "/tag/tagAdd",
      method: "post",
      data,
    });
  },
  // 标签编辑
  tagUpdate(data) {
    return request({
      url: "/tag/tagUpdate",
      method: "post",
      data,
    });
  },
  // 标签删除
  tagDelete(data) {
    return request({
      url: "/tag/tagDelete",
      method: "post",
      data,
    });
  },
};
