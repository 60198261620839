import { request } from '@/request/request';
import { parameterChange } from '@/utils';

export default {
  // 批次更新
  batchUpdate(data) {
    return request({
      url: '/offline_course/batchUpdate',
      method: 'post',
      data,
    });
  },
  // 批次列表
  batchPageList(data) {
    return request({
      url: '/offline_course/batchPageList?' + parameterChange(data),
      method: 'get',
      data,
    });
  },
  // 批次下拉列表
  batchList(data) {
    return request({
      url: '/offline_course/batchList?' + parameterChange(data),
      method: 'get',
      data,
    });
  },
  // 批次删除
  batchDelete(data) {
    return request({
      url: '/offline_course/batchDelete',
      method: 'post',
      data,
    });
  },
  // 获取定位
  getLocation(data) {
    return request({
      url: '/offline_course/getLocation?' + parameterChange(data),
      method: 'get',
      data,
    });
  },
};
