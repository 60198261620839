import { request } from "@/request/request";
import { parameterChange } from "@/utils";

export default {
  // 班级列表
  classPageList(data) {
    return request({
      url: "/team_class/classPageList?" + parameterChange(data),
      method: "get",
      data,
    });
  },
  // 班级新增
  classAdd(data) {
    return request({
      url: "/team_class/classAdd",
      method: "post",
      data,
    });
  },
  // 班级编辑
  classUpdate(data) {
    return request({
      url: "/team_class/classUpdate",
      method: "post",
      data,
    });
  },
  // 班级删除
  classDelete(data) {
    return request({
      url: "/team_class/classDelete",
      method: "post",
      data,
    });
  },
  // 班级详情
  classDetail(data) {
    return request({
      url: "/team_class/classDetail?" + parameterChange(data),
      method: "get",
      data,
    });
  },
  // 班级下拉列表
  classList(data) {
    return request({
      url: "/team_class/classList?" + parameterChange(data),
      method: "get",
      data,
    });
  },
};
