import { useUserStore } from '@/store/index';

const whiteList = ['login'];

export default function setupPermissionGuard (router) {
  router.beforeEach((to, from, next) => {
    const counter = useUserStore();
    if (whiteList.includes(to.name)) next();
    else counter.token ? next() : next({ path: '/user/login' });
  });
}
