import { request } from '@/request/request';
import { parameterChange } from '@/utils';

export default {
  // 管理员列表
  adminList(data) {
    return request({
      url: '/admin/adminList?' + parameterChange(data),
      method: 'get',
    });
  },
  // 新增管理员
  adminAdd(data) {
    return request({
      url: '/admin/adminAdd',
      method: 'post',
      data,
    });
  },
  // 删除管理员
  adminDelete(data) {
    return request({
      url: '/admin/adminDelete',
      method: 'post',
      data,
    });
  },
  // 管理员绑定团队
  adminTeamBind(data) {
    return request({
      url: '/admin/adminTeamBind',
      method: 'post',
      data,
    });
  },
  // 管理员绑定团队
  adminTeamUnbind(data) {
    return request({
      url: '/admin/adminTeamUnbind',
      method: 'post',
      data,
    });
  },
};
