export default [
  {
    path: '/404',
    component: () => import('@/views/exception/404'),
  },
  {
    path: '/:catchAll(.*)',
    redirect: '/404',
  },
];
