import { request } from "@/request/request";
import { parameterChange } from "@/utils";

export default {
  // 活动新增
  activityAdd(data) {
    return request({
      url: "/activity/activityAdd",
      method: "post",
      data,
    });
  },

  // 活动编辑
  activityUpdate(data) {
    return request({
      url: "/activity/activityUpdate",
      method: "post",
      data,
    });
  },

  // 活动详情
  activityDetail(data) {
    return request({
      url: "/activity/activityDetail?" + parameterChange(data),
      method: "get",
    });
  },

  // 活动列表
  activityPageList(data) {
    return request({
      url: "/activity/activityPageList?" + parameterChange(data),
      method: "get",
    });
  },

  // 活动删除
  activityDelete(data) {
    return request({
      url: "/activity/activityDelete",
      method: "post",
      data,
    });
  },

  // 活动绑定课程
  courseAssociate(data) {
    return request({
      url: "/activity/courseAssociate",
      method: "post",
      data,
    });
  },

  // 活动解绑课程
  courseDisassociate(data) {
    return request({
      url: "/activity/courseDisassociate",
      method: "post",
      data,
    });
  },

  // 活动绑定关系方
  partiesAssociate(data) {
    return request({
      url: "/activity/partiesAssociate",
      method: "post",
      data,
    });
  },

  // 活动解绑关系方
  partiesDisassociate(data) {
    return request({
      url: "/activity/partiesDisassociate",
      method: "post",
      data,
    });
  },

  // 活动关系方列表
  activityPartiesList(data) {
    return request({
      url: "/activity/partiesList?" + parameterChange(data),
      method: "get",
      data,
    });
  },
};
