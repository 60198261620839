import BlankLayout from "@/layout/BlankLayout";

export default {
  path: "/user",
  name: "user",
  component: BlankLayout,
  redirect: "/user/login",
  children: [
    {
      path: "login",
      name: "login",
      component: () => import("@/views/user/login"),
    },
  ],
};
