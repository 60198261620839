import { request } from "@/request/request";
import { parameterChange } from "@/utils";

export default {
  // 关系方新增
  partiesAdd(data) {
    return request({
      url: "/parties/partiesAdd",
      method: "post",
      data,
    });
  },

  // 关系方编辑
  partiesUpdate(data) {
    return request({
      url: "/parties/partiesUpdate",
      method: "post",
      data,
    });
  },

  // 关系方详情
  partiesDetail(data) {
    return request({
      url: "/parties/partiesDetail?" + parameterChange(data),
      method: "get",
    });
  },

  // 关系方列表
  partiesPageList(data) {
    return request({
      url: "/parties/partiesPageList?" + parameterChange(data),
      method: "get",
    });
  },

  // 关系方删除
  partiesDelete(data) {
    return request({
      url: "/parties/partiesDelete",
      method: "post",
      data,
    });
  },

  // 关系方下拉列表
  partiesList(data) {
    return request({
      url: "/parties/partiesList?" + parameterChange(data),
      method: "get",
      data,
    });
  },

  // 关系方下拉列表
  partiesList(data) {
    return request({
      url: "/parties/partiesList?" + parameterChange(data),
      method: "get",
      data,
    });
  },

  // 地区列表
  getAreaInfoList(data) {
    return request({
      url: "/parties/getAreaInfoList",
      method: "get",
      data,
    });
  },
};
