/*
 * @Date: 2022-08-16 09:31:08
 * @LastEditTime: 2023-03-15 09:15:29
 */
import { useUserStore, useCommonStore, useDirectoryStore } from "@/store/index";

// 刷新页面后设置缓存
const Initializer = () => {
  const counter = useUserStore();
  const commonStore = useCommonStore();
  const directoryStore = useDirectoryStore();

  counter.SET_TOKEN(localStorage.getItem("token"));
  counter.SET_ACCOUNT(localStorage.getItem("account"));
  counter.SET_ADMIN_ID(localStorage.getItem("adminId"));
  counter.SET_ROLE(localStorage.getItem("role"));

  let list = directoryStore.menu;
  if (localStorage.getItem("role") != 1)
    list = list.filter((item) => item.key != 10);
  directoryStore.SET_LIST(list);
};

// 设置为不输出日志（上线使用）
const rewirteLog = () => {
  console.log = (function (log) {
    return process.env.NODE_ENV === "production" ? function () {} : log;
  })(console.log);
};

export { Initializer, rewirteLog };
