/*
 * @Date: 2022-08-16 09:31:08
 * @LastEditTime: 2024-08-13 22:50:29
 */
import router from '@/router';
import api from '@/api';
import { defineStore } from 'pinia';
import { message } from 'ant-design-vue';
import 'ant-design-vue/dist/reset.css';
import useDirectoryStore from '../directory';

const useUserStore = defineStore('user', {
  state: () => ({
    token: '',
    adminId: '',
    account: '',
    role: '',
  }),

  getters: {
    userInfo(state) {
      return { ...state };
    },
  },
  actions: {
    SET_TOKEN(token) {
      this.token = token;
    },
    SET_ACCOUNT(account) {
      this.account = account;
    },
    SET_ADMIN_ID(adminId) {
      this.adminId = adminId;
    },
    SET_ROLE(role) {
      this.role = role;
    },
    login({ account = '', password = '', uuid = '', code = '' }) {
      if (!account || !password) {
        message.warning('请填写账号及密码', 2);
      } else {
        return new Promise((resolve, reject) => {
          const params = { account, password, uuid, code, type: 1 };
          api.login(params).then((res) => {
            if (res) {
              const { token, adminId, account, role } = res.data;
              this.token = token;
              this.adminId = adminId;
              this.account = account;
              this.role = role;
              localStorage.setItem('token', token);
              localStorage.setItem('adminId', adminId);
              localStorage.setItem('account', account);
              localStorage.setItem('role', role);
              router.push({ path: '/home' });
              message.success('登录成功', 2);

              const directoryStore = useDirectoryStore();
              let list = directoryStore.menu;
              if (role != 1) list = list.filter((item) => item.key != 10);
              directoryStore.SET_LIST(list);
              resolve();
            } else resolve(false);
          });
        });
      }
    },
    logout() {
      this.token = '';
      this.adminId = '';
      this.account = '';
      this.role = '';
      localStorage.removeItem('token');
      localStorage.removeItem('adminId');
      localStorage.removeItem('account');
      localStorage.removeItem('role');
      router.push({ name: 'login' });
      message.success('退出成功', 2);
    },
  },
});

export default useUserStore;
